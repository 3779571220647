$(function(){
  //for little notification
  $('div.alert').not('.alert-important').delay(3000).slideUp(300);
  //Subscribe to an event
  $.subscribe ('form.submitted', function(){
    $('.flash').fadeIn(500).delay(1000).fadeOut(500);
  });

})


